import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Row,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { ErrorMessage, Field, FieldArray, FormikErrors } from "formik";
import DatePicker from "react-datepicker";
import DatePickerReact from "../../../../../components/DatePicker/DatePickerReact";

export default function AddEducation({
  values,
  errors,
  touched,
  setFieldValue,
  activeTab,
}: any) {
  // State to manage which accordion item is open
  const [openIndex, setOpenIndex] = useState<number | undefined | null>(); // Open the first item initially

  useEffect(() => {
    setOpenIndex(null);
  }, [activeTab]);
  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index); // Toggle open/closed
  };
  const handleChange = (e: any, namez?: any) => {
    setFieldValue(namez, e);
  };

  return (
    <FieldArray name="qualifications">
      {({ push, remove }) => (
        <>
          <div className="d-float-center justify-content-between">
            <h4>QUALIFICATION</h4>
            <Button
              type="button"
              onClick={() =>
                push({
                  course_name: "",
                  institution_name: "",
                  start_date: "",
                  end_date: "",
                  is_current_study: false,
                  description: "",
                })
              }
              
              style={{ backgroundColor: "#6ecace" }}
            >
              <i className="ri-add-line" aria-hidden="true"></i> Add
              Qualification
            </Button>
          </div>
          <Accordion open={""} toggle={() => {}}>
            {values.qualifications.map((qualification: any, index: number) => (
              <AccordionItem className="mt-3 " key={index}>
                <AccordionHeader
                  targetId={index.toString()}
                  onClick={() => toggleAccordion(index)}
                  style={{
                    backgroundColor:
                      errors?.qualifications &&
                      errors.qualifications[index] &&
                      Object?.keys(errors?.qualifications)?.length <= 6
                        ? "#f8d7da"
                        : "",
                  }}
                >
                  <Row className="d-float-center justify-content-between">
                    <Col className="col-12">
                      {qualification?.course_name ? (
                        qualification?.course_name
                      ) : (
                        <> Qualification {index + 1}</>
                      )}

                      {/* <Button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          remove(index);
                        }}
                        color="danger"
                        className="ml-2"
                      >
                        <i className="ri-close-circle-fill"></i> Remove
                      </Button> */}
                    </Col>
                  </Row>
                </AccordionHeader>
                <AccordionBody
                  style={{ display: openIndex === index ? "block" : "none" }}
                  accordionId={index.toString()}
                >
                  <Row>
                    <div className="d-float-center justify-content-end text-danger">
                      {/* <FormGroup className="form-mb-0">
                        <Field
                          type="checkbox"
                          name={`qualifications.${index}.is_current_study`}
                          className="form-check-input"
                        />
                        <Label
                          className="form-mb-0"
                          for={`qualifications.${index}.is_current_study`}
                        >
                          &nbsp;Is Current Eduction
                        </Label>
                      </FormGroup>{" "} */}
                      {index !== 0 && (
                        <>
                          <i
                            className="ri-delete-bin-6-line"
                            onClick={(e) => {
                              e.stopPropagation();
                              remove(index);
                            }}
                          ></i>
                          &nbsp;Delete Row
                        </>
                      )}
                    </div>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`qualifications.${index}.course_name`}>
                          Course Name
                        </Label>
                        <Field
                          type="text"
                          name={`qualifications.${index}.course_name`}
                          className={`form-control ${
                            (
                              errors?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.course_name &&
                            (
                              touched?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.course_name
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`qualifications.${index}.course_name`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`qualifications.${index}.institution_name`}>
                          Institution Name
                        </Label>
                        <Field
                          type="text"
                          name={`qualifications.${index}.institution_name`}
                          className={`form-control ${
                            (
                              errors?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.institution_name &&
                            (
                              touched?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.institution_name
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`qualifications.${index}.institution_name`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`qualifications.${index}.start_date`}>
                          Start Date
                        </Label>
                        <DatePickerReact
                          selected={
                            values?.qualifications?.[index]?.start_date
                              ? new Date(
                                  values?.qualifications?.[index]?.start_date
                                )
                              : null
                          }
                          name={`qualifications.${index}.start_date`}
                          showIcon
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          onChange={(e: any) =>
                            handleChange(
                              e,
                              `qualifications.${index}.start_date`
                            )
                          }
                          maxDate={new Date()}
                          className={`form-control ${
                            (
                              errors?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.start_date &&
                            (
                              touched?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.start_date
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`qualifications.${index}.start_date`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-6">
                      <FormGroup>
                        <Label for={`qualifications.${index}.end_date`}>
                          End Date
                        </Label>
                        <DatePickerReact
                          selected={
                            values?.qualifications?.[index]?.end_date
                              ? new Date(
                                  values?.qualifications?.[index]?.end_date
                                )
                              : null
                          }
                          name={`qualifications.${index}.end_date`}
                          showIcon
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          onChange={(e: any) =>
                            handleChange(e, `qualifications.${index}.end_date`)
                          }
                          maxDate={new Date()}
                          className={`form-control ${
                            (
                              errors?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.end_date &&
                            (
                              touched?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.end_date
                              ? "is-invalid"
                              : ""
                          }`}
                          // disabled={values?.qualifications[index].is_current_study}
                        />
                        <ErrorMessage
                          name={`qualifications.${index}.end_date`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-12">
                      <FormGroup>
                        <Label for={`qualifications.${index}.description`}>
                          Description
                        </Label>
                        <Field
                          as="textarea"
                          name={`qualifications.${index}.description`}
                          className={`form-control ${
                            (
                              errors?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.description &&
                            (
                              touched?.qualifications?.[index] as FormikErrors<{
                                course_name: string;
                                institution_name: string;
                                start_date: string;
                                end_date: string;
                                is_current_study: boolean;
                                description: string;
                              }>
                            )?.description
                              ? "is-invalid"
                              : ""
                          }`}
                          style={{ height: "100px" }}
                        />
                        <ErrorMessage
                          name={`qualifications.${index}.description`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </>
      )}
    </FieldArray>
  );
}
