import { useNavigate } from "react-router-dom";
import { Button, Card } from "reactstrap";
import constants from "../../../../../Utils/Constants/Constants";

export default function SucccessContent() {
  const navigate = useNavigate();
  return (
    <>
      <div className="account-verified seeker mt-0">
        <div className="header-logo">
          <a className="d-flex justify-content-center pb-3 pt-4" href="/">
            <img
              className="uss-logo01"
              src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
              alt="USS Logo"
            />
          </a>
        </div>
        <Card className="p-50">
          <div className="text-center mb-3 logo-div01">
            <img
              src="https://i.pinimg.com/originals/32/b6/f2/32b6f2aeeb2d21c5a29382721cdc67f7.gif"
              alt=""
              className="justify-content-center"
              style={{
                width: "100px",
              }}
            />
            <h2 className="block-subTitle text-center uss-text-info mb-3">
              <span className="uss-text-primary">ACCOUNT VERIFIED</span>
            </h2>
            <span>
              Your account has been verified successfully, now create your and
              find your ideal support working you'r looking for!
            </span>
            <br />
            <Button
              className="btn uss-bg-primary mt-40"
              onClick={() => navigate("/create-profile-careseeker")}
            >
              Create Profile
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
