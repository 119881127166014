import { useNavigate } from "react-router-dom";
import { Button, Card } from "reactstrap";
import constants from "../../../../Utils/Constants/Constants";

export default function ConfirmModal({ setOther, onCloseModal }: any) {
  const navigate = useNavigate();
  return (
    <>
      <div className="account-verified caregiver mt-0">
        <div className="header-logo">
          <a className="d-flex justify-content-center pb-3 pt-4" href="/">
            <img
              className="uss-logo01"
              src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
              alt="USS Logo"
            />
          </a>
        </div>
        <Card className="p-30">
          <div className="text-center mb-3 logo-div01">
            <img
              src="https://cdn-icons-gif.flaticon.com/9284/9284464.gif"
              alt=""
              className="justify-content-center"
              style={{
                width: "100px",
              }}
            />
            <h2 className="block-subTitle text-center uss-text-info mb-3">
              <span className="uss-text-primary text-uppercase">
                Confirm Your Account
              </span>
            </h2>
            <span>
              Is this account for yourself or for someone else? If it’s for
              someone else, you must enter their personal details accurately.
            </span>
            <br />
            <div className="text-center mt-5 d-float-center justify-content-center gap-15 mt-4">
              <Button
                className="btn uss-bg-primary mt-40"
                onClick={() => {
                  setOther(1);
                  onCloseModal();
                }}
              >
                For Myself
              </Button>

              <Button
                className="btn uss-bg-info mt-40 "
                onClick={() => {
                  setOther(2);
                  onCloseModal();
                }}
              >
                For Someone Else
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
