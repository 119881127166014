import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import {
  getExpAgedCareData,
  getExpChildrenData,
  getIntersetData,
  getLangData,
  getLocations,
  getserviceTypeListDropdwon,
  getState,
  getWillingToWorkData,
} from "../../../Api/WebsiteApi";
import Filter from "./Filter";
import CareGiverList from "./CareGiverList";
import { useEffect, useState } from "react";
import { api } from "../../../Api/Api";
import moment from "moment";

export default function SearchOutput() {
  const [currentPage, setCurrentPage] = useState(1);
  const currentUrl = window.location.href;

  // Step 2: Create a URL object
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);
  const service_type_id: any = params.get("service_type_id");
  const suburb_id = params.get("suburb_id");
  const subrub = params.get("subrub");
  const radius = params.get("radius");
  const state_ = params.get("state");
  const [loaction, setLocation] = useState<any>(subrub ? subrub : "");
  const [state_input, setSatteInput] = useState<any>(state_ ? state_ : "");
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState<any>({
    state: state_ || "",
    service_type: service_type_id || "",
    suburb_id: suburb_id || null,
    gnder: "",
    lang: [],
    post_code: "",
    radius: "",
    sub_label: subrub || "",
    exp_aged_data: [],
    inter__data: [],
    exp_child_data: [],
    willing__data: [],
    avlibility_date: radius || "",
  });
  const clearValues = () => {
    setValues({
      state: "",
      service_type: [],
      suburb_id: null,
      gnder: "",
      lang: [],
      post_code: "",
      radius: "",
      sub_label: "",
      exp_aged_data: [],
      inter__data: [],
      exp_child_data: [],
      willing__data: [],
      avlibility_date: "",
    });
  };
  const handleChange = (name: any, value: any) => {
    setValues({
      ...values,
      [name]: value,
    });
  };
  // console.log(values, "values");
  function clearUrlParams() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);

    // Remove the parameters you want to clear
    params.delete("service_type_id");
    params.delete("suburb_id");
    params.delete("radius");
    params.delete("state_");
    params.delete("state");
    params.delete("subrub");
    setValues({
      state: "",
      service_type: [],
      suburb_id: null,
      gnder: "",
      lang: [],
      post_code: "",
      radius: "",
      sub_label: "",
      exp_aged_data: [],
      inter__data: [],
      exp_child_data: [],
      willing__data: [],
      avlibility_date: "",
    });

    // Update the URL without reloading the page
    const newUrl =
      url.origin +
      url.pathname +
      (params.toString() ? "?" + params.toString() : "");
    window.history.pushState({}, "", newUrl);
    window.location.reload();
  }

  // console.log(currentPage, "currentPage");
  useEffect(() => {
    setLoading(true);
    getData();
  }, [service_type_id, currentPage]);
  const getData = async () => {
    window.scrollTo(0, 0);
    const lang_array_: any = [];
    values?.lang?.map((item: any) => {
      lang_array_?.push(item?.value);
    });
    const inter__data: any = [];
    values?.inter__data?.map((item: any) => {
      inter__data?.push(item?.value);
    });
    const exp_child_data: any = [];
    values?.exp_child_data?.map((item: any) => {
      exp_child_data?.push(item?.value);
    });
    const willing__data: any = [];
    values?.willing__data?.map((item: any) => {
      willing__data?.push(item?.value);
    });
    const exp_aged_data: any = [];
    values?.exp_aged_data?.map((item: any) => {
      exp_aged_data?.push(item?.value);
    });
    const params = new URLSearchParams();

    // Add parameters only if their values are present
    if (lang_array_.length) params.append("languages", lang_array_.join(","));
    if (exp_aged_data.length)
      params.append("exp_aged_data", exp_aged_data.join(","));
    if (exp_child_data.length)
      params.append("exp_child_data", exp_child_data.join(","));
    if (willing__data.length)
      params.append("willing_to_work", willing__data.join(","));
    if (inter__data.length) params.append("inter__data", inter__data.join(","));

    if (values?.suburb_id) params.append("suburb_id", values?.suburb_id);

    if (values?.service_type?.value || values?.service_type)
      params.append(
        "service_type_id",
        values?.service_type?.value || values?.service_type
      );
    if (values?.gnder) params.append("gender", values?.gnder);
    if (values?.state) params.append("state", values?.state);
    if (values?.post_code) params.append("post_code", values?.post_code);
    if (values?.avlibility_date)
      params.append(
        "date",
        moment(values?.avlibility_date).format("YYYY-MM-DD")
      );

    const url = `/user/service_providers/list?page=${currentPage}&limit=${9}&${params.toString()}`;
    const [, data]: any = await api.get(
      url,

      true
    );
    setLoading(false);
    setData(data);
  };
  const List_data = () => {
    getData();
  };

  const { data: serviceTypes, isLoading: serviceTypesLoading }: any = useQuery(
    [`serviceTypes`],
    () => getserviceTypeListDropdwon(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  const { data: state, isLoading: sateLoading }: any = useQuery(
    [`state` + state_input],
    () => getState(state_input),
    {
      keepPreviousData: true,
      staleTime: 1000,
      // enabled: state_input ,
    }
  );
  const { data: location_data, isLoading: locationLoading }: any = useQuery(
    [`loaction`, loaction],
    () => getLocations(loaction),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  const { data: Lang_data }: any = useQuery(
    [`getLangData`],
    () => getLangData(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  const { data: exp_aged_data }: any = useQuery(
    [`exp_aged_data`],
    () => getExpAgedCareData(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  const { data: exp_child_data }: any = useQuery(
    [`getExpChildrenData`],
    () => getExpChildrenData(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  const { data: inter__data }: any = useQuery(
    [`inter__data`],
    () => getIntersetData(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  const { data: willing__data }: any = useQuery(
    [`willing__data`],
    () => getWillingToWorkData(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  return (
    <>
      <style>
        {`

          .items{
            margin-top:10px; justify-content: space-between;}
          .items2{
            display:none!important;
          }
          label { 
            font-family: 'Satoshi', serif !important;
            font-weight: 500;
          }
          .form-group input { 
            height: 38px; 
            padding: 11px 15px 13px 15px;
          }
            .service-type-result .light-badge {
              font-size: 12px;
              border-radius: 50px;
              padding: 11px 15px 11px 15px;              
            }
            .service-type-result .light-badge .search-output {
              color: #000;
            }
            .service-type-result .light-badge:hover, 
            .service-type-result .light-badge:hover .search-output {
              color: #fff;
            }              
        `}
      </style>
      <section className="section-box">
        <div className="breacrumb-cover bg-img-about bg-img-find-giver">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="mb-10">Care Givers List</h2>
              </div>
              {/* <div className="col-lg-6 text-lg-end">
                <ul className="breadcrumbs mt-40">
                  <li>
                    <a className="home-icon" href="#">
                      Home
                    </a>
                  </li>
                  <li>Care Givers List</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-5">
        {/* <div className="d-md-flex d-block mb-3 pb-3 border-bottom items2">
          <div className="page-titles d-flex items">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/careseeker/dashboard">
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Caregivers</a>
              </li>
            </ol>
          </div>
        </div> */}
        {/* <br /> */}
        <div className="find-care-givers">
          <div className="row mt-30">
            <div className="col-md-3 mb-4">
              <div className="card w-100">
                <div className="card-body">
                  <h5 className="card-title">
                    <i className="ri-filter-fill font-17"></i> Filters:
                  </h5>
                  <Filter
                    serviceTypes={serviceTypes}
                    setValues={setValues}
                    handleChange={handleChange}
                    state={state}
                    setLocation={setLocation}
                    location_data={location_data}
                    Lang_data={Lang_data}
                    List_data={List_data}
                    values={values}
                    setSatteInput={setSatteInput}
                    clearValues={clearValues}
                    willing__data={willing__data}
                    inter__data={inter__data}
                    exp_aged_data={exp_aged_data}
                    exp_child_data={exp_child_data}
                    clearUrlParams={clearUrlParams}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-9 mb-4 mt-030">
              <CareGiverList
                dataList={data}
                dataLoading={loading}
                radius={radius}
                setValues={setValues}
                handleChange={handleChange}
                clearUrlParams={clearUrlParams}
                values={values}
                state_={state_}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                List_data={List_data}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
