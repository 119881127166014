import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row } from "reactstrap";
import { SERVICES_TYPES_ } from "../../../../Utils/Constants/select_options";
import { useNavigate } from "react-router-dom";
import constants from "../../../../Utils/Constants/Constants";
import axios from "axios";
import { BaseUrl } from "../../../../Api/BaseUrl";
import ReactSelect from "react-select";
import { useQuery } from "react-query";
import {
  getServiceProvides,
  getserviceTypeList,
} from "../../../../Api/WebsiteApi";
import DatePickerReact from "../../../../components/DatePicker/DatePickerReact";

export default function Banner() {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [loaction, setLocation] = useState([]);
  const [values, setVlues] = useState({
    loaction: "",

    service_type: "",
    date: "",
    suburb_id: "",
  });
  const navigate = useNavigate();

  const bannerSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "ease-in-out",
    afterChange: (index: any) => setCurrentBannerIndex(index),
  };

  const slides = [
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/home/audiology.png`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/home/banner2.jpg`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/home/banner3.jpg`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/home/child-care.png`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/home/counselling.png`,
    },
    {
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/home/nurse.png`,
    },
  ];
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setVlues({
      ...values,
      [name]: value,
    });
  };
  useEffect(() => {
    axios
      .get(`${BaseUrl}/user/master/locations?search=${values?.loaction}`)
      .then((response) => {
        let new_array = response?.data?.data?.map((item: any) => ({
          value: item?.id,
          label: `${item?.locality} - ${item?.stateName} ${item?.postcode} `,
          label_only: item?.locality,
          state: item?.state,
        }));
        setLocation(new_array || []);
        // console.log(response.data?.data); // Handle the response data here
      })
      .catch((error) => {
        console.error("There was an error!", error); // Handle any errors here
      });
  }, [values?.loaction]);
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none", // Remove border
      boxShadow: "none", // Remove shadow
      "&:hover": {
        border: "none", // Remove border on hover
      },
      opacity: 2.8,
      backgroundColor: "white",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999,
      opacity: 2.8, /// Ensure the dropdown is on top
      backgroundColor: state.isFocused ? "white" : "white",
    }),
  };
  const { data: dataList }: any = useQuery(
    [`services_providers`],
    () => getServiceProvides(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );

  return (
    <>
      <style>
        {`          
          .box-location div.css-rlod8t-control input {
            height: 44px;
          }          
        `}
      </style>
      <section className="banner-hero hero-2 home-banner-heros">
        {/* Banner Slider */}
        <Slider {...bannerSettings}>
          {slides?.map((banner: any, index: any) => (
            <Fragment key={index}>
              <Row
                style={{
                  background: `url(${banner?.backgroundImage})`, // Static URL for the image
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "115vh",
                  display: index === currentBannerIndex ? "block" : "none",
                }}
                className="d-block align-items-center banner-row img-fluid mt-0 home-banner-imgs"
              ></Row>
            </Fragment>
          ))}
        </Slider>
        <div className="home-banner-content w-100 h-100">
          <div className="banner-center-fixed d-float-center">
            <div className="banner-inner">
              <div className="block-banner">
                <h1 className="text-42 text-uppercase color-white wow animate__animated animate__fadeInUp">
                  The <span className="color-green">Easiest Way</span>
                  <br className="d-none d-lg-block" />
                  to Get Trusted Support Workers
                </h1>
                <div
                  className="font-lg font-regular color-white mt-20 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  Each month, more than 2 million care seekers turn. Locate
                  nearby dependable carers and support personnel in your
                  community
                </div>
                <div
                  className="form-find mt-40 wow animate__animated animate__fadeIn"
                  data-wow-delay=".2s"
                >
                  <form className="home-service-list-form position-relative">
                    <div className="box-industry">
                      <select
                        className="form-input ps-10 mr-10 select-active input-industry"
                        name="service_type"
                        defaultValue={values?.service_type}
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected value={""}>
                          Select Service Type
                        </option>

                        {dataList?.map((item: any, index: any) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>

                    <div className="box-location">
                      <ReactSelect
                        name="loaction"
                        options={loaction}
                        onChange={(selectedOption: any) => {
                          setSelectedLocation(selectedOption);
                          setVlues({
                            ...values,
                            suburb_id: selectedOption?.value,
                          });
                        }}
                        onInputChange={(inputValue) => {
                          setVlues({
                            ...values,
                            loaction: inputValue,
                          });
                        }}
                        placeholder="Enter Location"
                        isClearable
                        styles={customStyles}
                      />
                    </div>

                    <div className="range-km01 mt-0">
                      <DatePickerReact
                        selected={values?.date ? new Date(values?.date) : null}
                        onChange={(date: any) =>
                          setVlues({
                            ...values,
                            date: date,
                          })
                        }
                        name="avlibility_date"
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        placeholderText="Choose Date"
                        className={`form-control `}
                        showIcon
                        minDate={new Date()}
                      />

                      {/* <input
                        type="range"
                        id="range"
                        min="1"
                        max="30"
                        step="0.25"
                        name="range"
                        value={values?.range}
                        onChange={(e) => handleChange(e)}
                      />
                      <output className="range-output" name="result">
                        {values?.range} KM
                      </output>  */}
                    </div>
                    <button
                      className="btn btn-default btn-find font-sm mt-0"
                      onClick={() =>
                        navigate(
                          `/find/caregivers?service_type_id=${
                            values?.service_type ? values?.service_type : ""
                          }&suburb_id=${
                            values?.loaction
                              ? values?.loaction
                              : values?.suburb_id
                          }&radius=${values?.date ? values?.date : ""}&subrub=${
                            selectedLocation?.label_only
                              ? selectedLocation?.label_only
                              : ""
                          }&state=${
                            selectedLocation?.state
                              ? selectedLocation?.state
                              : ""
                          }`
                        )
                      }
                    >
                      Search
                    </button>
                  </form>
                </div>
                <div className="list-tags-banner mt-20" data-wow-delay=".3s">
                  <strong>Browse our Topics:</strong>
                  <a href="#">Becoming a support worker</a>,{" "}
                  <a href="#">NDIS</a>, <a href="#">Aged Care</a>,{" "}
                  <a href="#">Building your support team</a>,
                  <br />
                  <a href="#">Home Care Package</a>, <a href="#">Dementia</a>
                </div>
              </div>
              <div className="mt-60">
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-6 text-center mb-20">
                    <div className="d-inline-block text-start">
                      <h4 className="color-white">
                        {" "}
                        <span className="count">265</span>
                        <span> +</span>
                      </h4>
                      <p className="font-sm color-text-mutted">
                        Daily Care Seeking
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-6 text-center mb-20">
                    <div className="d-inline-block text-start">
                      <h4 className="color-white">
                        <span className="count">10</span>
                        <span> K+</span>
                      </h4>
                      <p className="font-sm color-text-mutted">Care Seekers</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-6 text-center mb-20">
                    <div className="d-inline-block text-start">
                      <h4 className="color-white">
                        {" "}
                        <span className="count">17</span>
                        <span> K+</span>
                      </h4>
                      <p className="font-sm color-text-mutted">Care Givers</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-6 text-center mb-20">
                    <div className="d-inline-block text-start">
                      <h4 className="color-white">
                        {" "}
                        <span className="count">28</span>
                        <span> K+</span>
                      </h4>
                      <p className="font-sm color-text-mutted">Happy members</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
