import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export default function SuccessCareSeekerModal({
  toggle,
  isOpen,
  onClosed,
  title,
  modalBodyClassName,
  children,
  size
}: any) {
  return (
    <>
      <Modal
        backdrop={onClosed}
        isOpen={isOpen}
        centered
        toggle={toggle}
        onClosed={onClosed}
        size={size}
      >
        {children}
      </Modal>
    </>
  );
}
