import React from "react";
import { FormGroup, Input } from "reactstrap";
import { Field } from "formik";

const InputSelectCommon = ({
  data,
  size,
  selectClass,
  name,
  value,
  disabled,
}: any) => {
  return (
    <Field
      as="select"
      size={1}
      className={selectClass}
      name={name}
      disabled={disabled}
    >
      <option value={""}>Please select</option>
      {data?.map(
        (
          item: {
            id: string | number | readonly string[] | undefined;
            name: any;
          },
          index: React.Key | null | undefined
        ) => {
          return (
            <option key={index} value={item?.id}>
              {item?.name}
            </option>
          );
        }
      )}
    </Field>
  );
};

export default InputSelectCommon;
