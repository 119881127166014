/* eslint-disable @typescript-eslint/no-unused-expressions */
import { toast } from "react-toastify";
import axios from "axios";
import { BaseUrl } from "../../../Api/BaseUrl";
import { RATES } from "../../../Utils/Constants/select_options";
import { isNumeric } from "../../../Utils/helpers/helpers";
import Swal from "sweetalert2";
import { initialValuesStep_2 } from "./ProfileValidation";
import moment from "moment";
import { useFormikContext } from "formik";
import { useEffect } from "react";

export const ProfileSubmitHandler = (
  values: any,
  // actions: any,
  setStep: any,
  UserListRefetch: any,
  setIsSubmitting: any
) => {
  setIsSubmitting(true);
  const formData = new FormData();
  formData.append("step", "1");

  if (values?.profile_img) {
    formData.append("profile_img", values?.profile_img);
  }

  formData.append("profile_type", values?.profile_type);
  formData.append("dob", moment(values?.dob).format("YYYY-MM-DD"));
  formData.append("gender", values?.gender);
  formData.append("residency_status", values?.residency_status);
  formData.append("address_line_one", values?.address_line_one);
  formData.append("address_line_two", values?.address_line_two);
  formData.append("email", values?.email);
  formData.append("mobile", values?.mobile);
  formData.append("last_name", values?.last_name);
  formData.append("first_name", values?.first_name);
  formData.append("state", values?.state);
  formData.append("address_suburb", values?.address_suburb);
  formData.append("street_number", values?.street_number);

  axios({
    method: "post",
    url: `${BaseUrl}/user/caregiver_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        setIsSubmitting(true);
        UserListRefetch();
        setStep(2);
        window.scrollTo(0, 0);
      }
    })
    .catch(function (response) {
      setIsSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        Swal.fire({
          title: "Error!",
          text: response?.response?.data?.message,
          icon: "error",
        });
        window.scrollTo(0, 0);
      }
    });
};

export const ProfileSubmitHandlerStep2 = (
  values: any,
  actions: any,
  setStep: any,
  UserListRefetch: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "4");

  formData.append("service_radius", values?.service_radius);
  formData.append(
    "suburb",
    isNumeric(values?.suburb) ? values?.suburb : values?.sub_id
  );
  formData.append("all_day_availability", values?.all_day_availability);

  values.availability_details
    .filter((detail: any) => detail.toggleSwitch === true) // Filter out only those where toggleSwitch is true
    .forEach(
      (
        detail: {
          day: string | Blob;
          start_time: any;
          end_time: any;
          hourly_rate: string | Blob;
        },
        index: any
      ) => {
        formData.append(`availability_details[${index}][day]`, detail.day);
        formData.append(
          `availability_details[${index}][start_time]`,
          moment(detail?.start_time).format("HH:mm:ss")
        );
        formData.append(
          `availability_details[${index}][end_time]`,
          moment(detail?.end_time).format("HH:mm:ss")
        );
        formData.append(
          `availability_details[${index}][hourly_rate]`,
          detail.hourly_rate
        );
      }
    );

  axios({
    method: "post",
    url: `${BaseUrl}/user/caregiver_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      // console.log(response, "response");
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        setStep(5);
        UserListRefetch();
        window.scrollTo(0, 0);
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
        window.scrollTo(0, 0);
        // toast.error("Unexpected Error Occurred");
      }
    });
};
export const ProfileSubmitHandlerStep3 = (
  values: any,
  // setIsSubmitting: any,
  setStep: any,
  getUser: any,
  setIsSubmitting: any,
  UserListRefetch: any
) => {
  console.log(values, "values");
  setIsSubmitting(true);
  const formData = new FormData();
  formData.append("step", "3");
  // Append non-file fields
  formData.append("document_id", values.document_id);
  formData.append(
    "document_id_expire",
    moment(values.document_id_expire).format("YYYY-MM-DD")
  );
  formData.append("police_verification_id", values?.police_verification_id);
  formData.append(
    "police_verification_id_expire",
    moment(values.police_verification_id_expire).format("YYYY-MM-DD")
  );
  formData.append("wwcc_id", values.wwcc_id);
  formData.append(
    "wwcc_id_expire",
    moment(values.wwcc_id_expire).format("YYYY-MM-DD")
  );
  formData.append("first_aid_certificate_id", values.first_aid_certificate_id);
  formData.append(
    "first_aid_certificate_expire",
    moment(values.first_aid_certificate_expire).format("YYYY-MM-DD")
  );

  // Append file fields if present
  if (values?.document_id_img) {
    values.document_id_img.forEach((item: { file: File }, index: number) => {
      item.file ? formData.append(`document_id_img[${index}]`, item.file) : ""; // Append file, not the entire object
    });
  }

  if (values?.police_verification_id_img) {
    values.police_verification_id_img.forEach(
      (item: { file: File }, index: number) => {
        item.file
          ? formData.append(`police_verification_id_img[${index}]`, item.file)
          : "";
      }
    );
  }

  if (values?.wwcc_id_img) {
    values.wwcc_id_img.forEach((item: { file: File }, index: number) => {
      item.file ? formData.append(`wwcc_id_img[${index}]`, item.file) : "";
    });
  }

  if (values?.first_aid_certificate_img) {
    values.first_aid_certificate_img.forEach(
      (item: { file: File }, index: number) => {
        item.file
          ? formData.append(`first_aid_certificate_img[${index}]`, item.file)
          : "";
      }
    );
  }

  axios({
    method: "post",
    url: `${BaseUrl}/user/caregiver_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      // console.log(response, "response");
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: "Details added successfully",
          icon: "success",
        });
        UserListRefetch();
        getUser();
        // toast.success("Detiles added successfully");
        setIsSubmitting(true);
        setStep(4);
        window.scrollTo(0, 0);
      }
    })
    .catch(function (response) {
      setIsSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        Swal.fire({
          title: "Error!",
          text: response?.response?.data?.message,
          icon: "error",
        });
        window.scrollTo(0, 0);
      }
    });
};
export const ProfileSubmitHandlerStep4 = (
  values: any,
  actions: any,
  setStep: any,
  UserListRefetch: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "2");

  // Append simple fields

  formData.append("about_yourself", values.about_yourself);
  formData.append("okay_with_pets", values.okay_with_pets);

  // Append work experiences array
  values?.work_experiences.forEach((experience: any, index: any) => {
    formData.append(
      `work_experiences[${index}][company_name]`,
      experience.company_name
    );
    formData.append(
      `work_experiences[${index}][designation]`,
      experience.designation
    );
    formData.append(
      `work_experiences[${index}][start_date]`,
      moment(experience?.start_date).format("YYYY-MM-DD")
    );
    formData.append(
      `work_experiences[${index}][end_date]`,
      moment(experience?.end_date).format("YYYY-MM-DD")
    );
    formData.append(
      `work_experiences[${index}][is_current_job]`,
      experience.is_current_job
    );
    formData.append(
      `work_experiences[${index}][description]`,
      experience.description
    );
  });

  // Append qualifications array
  values?.qualifications.forEach((qualification: any, index: any) => {
    formData.append(
      `qualifications[${index}][course_name]`,
      qualification.course_name
    );
    formData.append(
      `qualifications[${index}][institution_name]`,
      qualification.institution_name
    );
    formData.append(
      `qualifications[${index}][start_date]`,
      moment(qualification.start_date).format("YYYY-MM-DD")
    );
    formData.append(
      `qualifications[${index}][end_date]`,
      moment(qualification.end_date).format("YYYY-MM-DD")
    );
    formData.append(
      `qualifications[${index}][is_current_study]`,
      qualification.is_current_study
    );
    formData.append(
      `qualifications[${index}][description]`,
      qualification.description
    );
  });
  values?.languages_spokens.forEach((language: any, index: number) => {
    formData.append(
      `languages_spokens[${index}][language]`,
      language.value // Convert the language ID to a string before appending
    );
  });
  values?.interests.forEach((interest: any, index: number) => {
    formData.append(
      `interests[${index}][interest]`,
      interest.value // Convert the language ID to a string before appending
    );
  });
  values?.care_tasks.forEach((care_task_name: any, index: number) => {
    formData.append(
      `service_provides[${index}][service_provides]`,
      care_task_name.value // Convert the language ID to a string before appending
    );
  });
  values?.willing_to_work.forEach((willing_to_work: any, index: number) => {
    formData.append(
      `willing_to_work[${index}][willing_to_work]`,
      willing_to_work.value // Convert the language ID to a string before appending
    );
  });
  values?.experience_with_children.forEach(
    (experience_with_children: any, index: number) => {
      formData.append(
        `experience_with_children[${index}][experience_with_children]`,
        experience_with_children.value // Convert the language ID to a string before appending
      );
    }
  );
  values?.experience_with_aged_cares.forEach(
    (experience_with_aged_cares: any, index: number) => {
      formData.append(
        `experience_with_aged_cares[${index}][experience_with_aged_care]`,
        experience_with_aged_cares.value // Convert the language ID to a string before appending
      );
    }
  );

  axios({
    method: "post",
    url: `${BaseUrl}/user/caregiver_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      // console.log(response, "response");
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        setStep(3);
        window.scrollTo(0, 0);
        UserListRefetch();
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
        window.scrollTo(0, 0);
        // toast.error("Unexpected Error Occurred");
      }
    });
};

export const ProfileSubmitHandler5 = (
  values: any,
  actions: any,
  navigate: any,
  setStep: any,
  UserListRefetch: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "5");

  formData.append("abn", values?.abn);
  formData.append("extra_charge_after_hours", values?.extra_charge_after_hours);
  formData.append("bank_name", values?.bank_name);
  formData.append("bank_branch_name", values?.bank_branch_name);
  formData.append("bank_account_number", values?.bank_account_number);
  formData.append("company_name", values?.company_name);

  const newArray: any = [];
  // eslint-disable-next-line array-callback-return
  // values?.rates?.map((item: any) => {
  newArray?.push({
    sunday: values?.rates?.[0]?.min,
    saturday: values?.rates?.[1]?.min,
    public_holiday: values?.rates?.[2]?.min,
    weekday: values?.rates?.[3]?.min,
  });
  // });
  const ratesJSON = JSON.stringify(newArray?.[0]);
  // console.log(newArray[0], "newArray");
  // Append the JSON string to `FormData`
  formData.append("rates", ratesJSON);

  axios({
    method: "post",
    url: `${BaseUrl}/user/caregiver_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        UserListRefetch();
        setStep(6);
        window.scrollTo(0, 0);
        // navigate("/success?profile_completed=1");
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
        window.scrollTo(0, 0);
        // toast.error("Unexpected Error Occurred");
      }
    });
};

export const addModalInitialValuesHandlerStep1 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  // let new_arry = [];
  // new_arry?.push({
  //   value: UserData?.user_details?.profileType?.id,
  //   label: UserData?.user_details?.profileType?.name,
  // });
  setAddModalInitialValues({
    profile_type: UserData?.user_details?.profileType?.id
      ? UserData?.user_details?.profileType?.id
      : "",
    dob: UserData?.user_details?.dob ? UserData?.user_details?.dob : "",
    gender: UserData?.user_details?.gender?.id
      ? UserData?.user_details?.gender?.id?.toString()
      : "",
    residency_status: UserData?.user_details?.residencyStatus?.id
      ? UserData?.user_details?.residencyStatus?.id
      : "",
    address: UserData?.user_details?.address
      ? UserData?.user_details?.address
      : "",
    profile_img: UserData?.user_details?.profileImg,
    profile_img_: UserData?.user_details?.profileImg,

    email: UserData?.email ? UserData?.email : "",
    mobile: UserData?.mobile ? UserData?.mobile : "",
    name: UserData?.name ? UserData?.name : "",
  });
};

export const addModalInitialValuesHandlerStep2 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  // console.log(UserData, "UserData");
  const exp_array: any = [];
  UserData?.user_details?.work_experiences?.map((item: any, index: number) => {
    exp_array?.push({
      company_name: item?.companyName,
      designation: item?.designation,
      start_date: item?.startDate,
      end_date: item?.endDate,
      is_current_job: item?.isCurrentJob === 0 ? false : true,
      description: item?.description,
    });
  });
  const quali_array: any = [];
  UserData?.user_details?.qualifications?.map((item: any, index: number) => {
    quali_array?.push({
      course_name: item?.courseName,
      institution_name: item?.institutionName,
      start_date: item?.startDate,
      end_date: item?.endDate,
      is_current_study: item?.isCurrentJob === 0 ? false : true,
      description: item?.description,
    });
  });
  const lang_array: any = [];
  UserData?.user_details?.languages_spokens?.map((item: any, index: number) => {
    lang_array?.push({
      value: item?.language?.id,
      label: item?.language?.name,
    });
  });
  const interst_array: any = [];
  UserData?.user_details?.interests?.map((item: any, index: number) => {
    interst_array?.push({
      value: item?.interest?.id,
      label: item?.interest?.name,
    });
  });
  const care_array: any = [];
  UserData?.user_details?.service_provides?.map((item: any, index: number) => {
    care_array?.push({
      value: item?.serviceProvides?.id,
      label: item?.serviceProvides?.name,
    });
  });
  const iwilling_array: any = [];
  UserData?.user_details?.willing_to_work?.map((item: any, index: number) => {
    iwilling_array?.push({
      value: item?.willingToWork?.id,
      label: item?.willingToWork?.name,
    });
  });
  const exp_agedcares_array: any = [];
  UserData?.user_details?.experience_with_aged_cares?.map(
    (item: any, index: number) => {
      exp_agedcares_array?.push({
        value: item?.experienceWithAgedCare?.id,
        label: item?.experienceWithAgedCare?.name,
      });
    }
  );

  const exp_childresn_array: any = [];
  UserData?.user_details?.experience_with_children?.map(
    (item: any, index: number) => {
      exp_childresn_array?.push({
        value: item?.experienceWithChildren?.id,
        label: item?.experienceWithChildren?.name,
      });
    }
  );

  setAddModalInitialValues({
    about_yourself: UserData?.user_details?.aboutYourself,
    okay_with_pets:
      UserData?.user_details?.okayWithPets === 0 ? "false" : "true",
    work_experiences:
      exp_array?.length !== 0
        ? exp_array
        : [
            {
              company_name: "",
              designation: "",
              start_date: "",
              end_date: "",
              is_current_job: false,
              description: "",
            },
          ],
    qualifications:
      quali_array?.length !== 0
        ? quali_array
        : [
            {
              course_name: "",
              institution_name: "",
              start_date: "",
              end_date: "",
              is_current_study: false,
              description: "",
            },
          ],
    languages_spokens: lang_array ? lang_array : [],
    interests: interst_array ? interst_array : [],
    care_tasks: care_array ? care_array : [],
    willing_to_work: iwilling_array ? iwilling_array : [],
    experience_with_aged_cares: exp_agedcares_array ? exp_agedcares_array : [],
    experience_with_children: exp_childresn_array ? exp_childresn_array : [],
  });
};

export const addModalInitialValuesHandlerStep3 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  // console.log(UserData, "UserData");
  let new_arry = [];
  new_arry?.push({
    value: UserData?.user_details?.profileType?.id,
    label: UserData?.user_details?.profileType?.name,
  });
  setAddModalInitialValues({
    document_id: UserData?.user_details?.documentId,
    document_id_expire: UserData?.user_details?.documentIdExpire,
    document_id_img: UserData?.user_details?.documentIdImg, // Use null for FileBuffer as it will be a file object

    // document_id_img: "",
    // police_verification_id: "",
    police_verification_id_expire:
      UserData?.user_details?.policeVerificationIdExpire,
    police_verification_id_img: UserData?.user_details?.policeVerificationIdImg,
    // police_verification_id_img: "", // Use null for FileBuffer as it will be a file object
    wwcc_id: UserData?.user_details?.wwccId,
    wwcc_id_expire: UserData?.user_details?.wwccIdExpire,
    // wwcc_id_img: "",
    wwcc_id_img: UserData?.user_details?.wwccIdImg, // Use null for FileBuffer as it will be a file object
    first_aid_certificate_id: UserData?.user_details?.firstAidCertificateId,
    first_aid_certificate_expire:
      UserData?.user_details?.firstAidCertificateExpire,
    first_aid_certificate_img: UserData?.user_details?.firstAidCertificateImg,
    // first_aid_certificate_img: "",
  });
};

export const addModalInitialValuesHandlerStep4 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  // console.log(UserData, "UserData");
  if (UserData?.user_details?.availability_details?.length !== 0) {
    const new_arry: any = [1, 2, 3, 4, 5, 6, 7].map((day) => {
      const availability = UserData?.user_details?.availability_details?.find(
        (item: any) => item?.day?.id === day
      );

      return {
        id: availability?.id, // Use profileType id or default value
        day: day.toString(),
        start_time: availability
          ? new Date(`1970-01-01T${availability?.startTime}:00`)
          : null, // Use availability data if present
        end_time: availability
          ? new Date(`1970-01-01T${availability?.endTime}:00`)
          : null, // Otherwise, set to empty
        toggleSwitch: availability ? true : false,
      };
    });
    setAddModalInitialValues({
      suburb: UserData?.user_details?.suburb?.locality
        ? `${UserData?.user_details?.suburb?.locality || ""}-${
            UserData?.user_details?.suburb?.state || ""
          }  ${UserData?.user_details?.suburb?.postcode || ""}`
        : "",

      service_radius: UserData?.user_details?.serviceRadius
        ? UserData?.user_details?.serviceRadius
        : "",
      all_day_availability:
        UserData?.user_details?.allDayAvailability === 0 ? false : true,
      start_time:
        UserData?.user_details?.allDayAvailability === 0
          ? null
          : new Date(`1970-01-01T${UserData?.user_details?.availability_details?.[0]?.startTime}:00`),
      end_time:
        UserData?.user_details?.allDayAvailability === 0
          ? null
          : new Date(`1970-01-01T${UserData?.user_details?.availability_details?.[0]?.endTime}:00`),
      availability_details: new_arry,
      state: UserData?.user_details?.state?.id,
      sub_id: UserData?.user_details?.suburb?.id,
    });
  } else {
    setAddModalInitialValues(initialValuesStep_2);
  }
};
export const addModalInitialValuesHandlerStep5 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  const newarra: any = [];
  // eslint-disable-next-line array-callback-return
  UserData?.user_details?.caregiver_charges?.map((rate: any) => {
    newarra.push({
      id: rate?.id,
      value: "",
      label: rate?.dayType?.name,
      // field: rate?.field,
      min: rate.rate,
      // max: rate.max,
    });
  });
  // console.log(newarra[0], "newarra");
  setAddModalInitialValues({
    extra_charge_after_hours:
      UserData?.user_details?.extraChargeAfterHours === 1 ? true : false,

    rates:
      newarra?.length === 0
        ? RATES?.map((rate) => ({
            id: rate.value,
            value: "",
            label: rate?.label,
            field: rate?.field,
            min: "",
            // max: "",
          }))
        : newarra,
    abn: UserData?.user_details?.abn,
    bank_name: UserData?.user_details?.bankName,
    bank_branch_name: UserData?.user_details?.bankBranchName,
    bank_account_number: UserData?.user_details?.bankAccountNumber,
    company_name: UserData?.user_details?.companyName,
  });
};

// Utility function to extract field names with errors
const getFieldErrorNames = (errors: any, prefix = ""): string[] => {
  let errorFields: string[] = [];

  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const error = errors[key];
      const fieldName = prefix ? `${prefix}.${key}` : key;

      if (typeof error === "object") {
        // Recursive call for nested objects (e.g. arrays or complex objects)
        errorFields = [...errorFields, ...getFieldErrorNames(error, fieldName)];
      } else {
        errorFields.push(fieldName);
      }
    }
  }

  return errorFields;
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length === 0) return;

    // Target all types of form fields, not just input
    const element: HTMLElement | null = document.querySelector(
      `input[name='${fieldErrorNames[0]}'], textarea[name='${fieldErrorNames[0]}'], select[name='${fieldErrorNames[0]}']`
    );

    if (!element) return;

    // Scroll and focus on the field with error
    element.scrollIntoView();
    element.focus();
  }, [submitCount, isValid, errors]);

  return null; // This is a side-effect-only component, no UI rendered
};
