/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Spinner,
  Badge,
  Alert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ProfileSubmitHandlerStep3 } from "../ProfileMethods/ProfileSubmitHandlers";
import { api } from "../../../Api/Api";
import moment from "moment";
import DatePickerReact from "../../../components/DatePicker/DatePickerReact";

const Step3 = ({ setStep, UserListRefetch }: any) => {
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState<any>({
    document_id: "",
    document_id_expire: null,
    document_id_img: [], // Array to store multiple document images
    police_verification_id: "",
    police_verification_id_expire: null,
    police_verification_id_img: [], // Array to store multiple police verification images
    wwcc_id: "",
    wwcc_id_expire: null,
    wwcc_id_img: [], // Array to store multiple WWCC images
    first_aid_certificate_id: "",
    first_aid_certificate_expire: null,
    first_aid_certificate_img: [], // Array to store multiple first aid images
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    api
      .get("/user/user_details", true)
      .then(([status, response]) => {
        const data = response?.data;
        const documentIdArray = data?.user_details?.documents?.filter(
          (doc: any) => doc.documentName === "document_id"
        );
        const policeVerificationArray = data?.user_details?.documents?.filter(
          (doc: any) => doc.documentName === "police_verification_id"
        );
        const wwccIdArray = data?.user_details?.documents?.filter(
          (doc: any) => doc.documentName === "wwcc_id"
        );
        const firstAidCertificateArray = data?.user_details?.documents?.filter(
          (doc: any) => doc.documentName === "first_aid_certificate"
        );

        setFormData({
          ...formData,
          document_id: data?.user_details?.documentId || "",
          document_id_expire: data?.user_details?.documentIdExpire || null,
          document_id_img: documentIdArray || [],
          police_verification_id:
            data?.user_details?.policeVerificationId || "",
          police_verification_id_expire:
            data?.user_details?.policeVerificationIdExpire || null,
          police_verification_id_img: policeVerificationArray || [],
          wwcc_id: data?.user_details?.wwccId || "",
          wwcc_id_expire: data?.user_details?.wwccIdExpire || null,
          wwcc_id_img: wwccIdArray || [],
          first_aid_certificate_id:
            data?.user_details?.firstAidCertificateId || "",
          first_aid_certificate_expire:
            data?.user_details?.firstAidCertificateExpire || null,
          first_aid_certificate_img: firstAidCertificateArray || [],
        });
      })
      .catch((err) => {});
  };

  const handleMultipleImagesChange = (event: any, fieldName: string) => {
    const files = event.target.files;
    const updatedImages = Array.from(files).map((file: any) => ({
      file,
      preview: URL.createObjectURL(file),
      documentType: file?.type,
    }));

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [fieldName]: [...prevFormData[fieldName], ...updatedImages],
    }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    namez?: any
  ) => {
    if (
      namez === "first_aid_certificate_expire" ||
      namez === "police_verification_id_expire" ||
      namez === "document_id_expire" ||
      namez === "wwcc_id_expire"
    ) {
      setFormData({ ...formData, [namez]: e });
      setErrors((prevErrors: any) => ({ ...prevErrors, [namez]: undefined }));
    } else {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: undefined }));
    }
  };
  // console.log(formData, "formData");
  const fieldRefs = useRef<{
    [key: string]: HTMLElement | null;
  }>({
    document_id: null,
    document_id_expire: null,
    document_id_img: null, // Assuming you'll handle images separately
    police_verification_id: null,
    police_verification_id_expire: null,
    police_verification_id_img: null,
    wwcc_id: null,
    wwcc_id_expire: null,
    wwcc_id_img: null,
    first_aid_certificate_id: null,
    first_aid_certificate_expire: null,
    first_aid_certificate_img: null,
  });

  const validateForm = () => {
    let newErrors: any = {};

    // Regular expressions for each document ID format
    const driversLicenseRegex = /^\d{8}$/; // 8 digits
    const policeVerificationRegex = /^\d{7}PC$/; // 7 digits followed by PC
    const wwccIdRegex = /^WWC\d{7}[A-Z]$/; // WWC followed by 7 digits and 1 alphabet
    const firstAidIdRegex = /^[a-zA-Z0-9]{6,14}$/; // 6-14 alphanumeric characters
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/; // Date in dd/mm/yyyy format

    // Function to check if a date is in the future
    const isFutureDate = (dateString: any) => {
      const dateString_ = moment(dateString).format("DD/MM/YYYY");
      const [day, month, year] = dateString_?.split("/").map(Number);
      const date = new Date(year, month - 1, day); // Month is 0-indexed in JS
      return date > new Date();
    };

    // Validate Drivers License ID
    if (!formData.document_id) {
      newErrors.document_id = "Document ID is required";
    } else if (!driversLicenseRegex.test(formData.document_id)) {
      newErrors.document_id = "Document ID must be 8 digits";
    }

    // Validate Document ID Expiry Date
    if (!formData.document_id_expire) {
      newErrors.document_id_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.document_id_expire)) {
      newErrors.document_id_expire = "Expiry date must be in the future";
    }

    if (formData.document_id_img?.length === 0) {
      newErrors.document_id_img = "Document is required";
    }

    // Validate Police Verification ID and Expiry Date
    if (!formData.police_verification_id) {
      newErrors.police_verification_id = "Police verification ID is required";
    } else if (!policeVerificationRegex.test(formData.police_verification_id)) {
      newErrors.police_verification_id =
        "Police verification ID must be 7 digits followed by 'PC' Eg:2324567PC";
    }

    if (!formData.police_verification_id_expire) {
      newErrors.police_verification_id_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.police_verification_id_expire)) {
      newErrors.police_verification_id_expire =
        "Expiry date must be in the future";
    }

    if (formData.police_verification_id_img?.length === 0) {
      newErrors.police_verification_id_img = "Document is required";
    }

    // Validate WWCC ID and Expiry Date
    if (!formData.wwcc_id) {
      newErrors.wwcc_id = "WWCC ID is required";
    } else if (!wwccIdRegex.test(formData.wwcc_id)) {
      newErrors.wwcc_id =
        "WWCC ID must be 'WWC' followed by 7 digits and 1 alphabet Eg:WWC1237890D";
    }

    if (!formData.wwcc_id_expire) {
      newErrors.wwcc_id_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.wwcc_id_expire)) {
      newErrors.wwcc_id_expire = "Expiry date must be in the future";
    }

    if (formData?.wwcc_id_img?.length === 0) {
      newErrors.wwcc_id_img = "Document is required";
    }

    // Validate First Aid Certificate ID and Expiry Date
    if (!formData.first_aid_certificate_id) {
      newErrors.first_aid_certificate_id =
        "First aid certificate ID is required";
    } else if (!firstAidIdRegex.test(formData.first_aid_certificate_id)) {
      newErrors.first_aid_certificate_id =
        "First aid certificate ID must be 6-14 alphanumeric characters";
    }

    // Validate First Aid Certificate Expiry Date
    if (!formData.first_aid_certificate_expire) {
      newErrors.first_aid_certificate_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.first_aid_certificate_expire)) {
      newErrors.first_aid_certificate_expire =
        "Expiry date must be in the future";
    }

    if (formData?.first_aid_certificate_img?.length === 0) {
      newErrors.first_aid_certificate_img = "Document is required";
    }

    // Additional checks for file types (e.g., PNG validation for all document images)
    const fileTypeRegex = /^(png|jpg|jpeg|gif|pdf|jif)$/;

    const fileTypeRegex_ =
      /^(image\/(png|jpg|jpeg|gif|jif)|application\/pdf)$/i;

    // Function to check if all selected files match the allowed types
    const checkFileType = (files: any) => {
      return files?.map((file: any, index: any) =>
        file?.documentType
          ? fileTypeRegex?.test(file?.documentType)
          : fileTypeRegex_?.test(file?.documentTyp)
      );
    };
    // Validate file uploads (ensure images are PNGs)
    if (formData.document_id_img && !checkFileType(formData.document_id_img)) {
      newErrors.document_id_img =
        "Invalid file type. Only PDF, JPG, JPEG, PNG, GIF, and JIF are allowed";
    }

    if (
      formData.police_verification_id_img &&
      !checkFileType(formData.police_verification_id_img)
    ) {
      newErrors.police_verification_id_img =
        "Invalid file type. Only PDF, JPG, JPEG, PNG, GIF, and JIF are allowed";
    }

    if (formData.wwcc_id_img && !checkFileType(formData.wwcc_id_img)) {
      newErrors.wwcc_id_img =
        "Invalid file type. Only PDF, JPG, JPEG, PNG, GIF, and JIF are allowed";
    }

    if (
      formData.first_aid_certificate_img &&
      !checkFileType(formData.first_aid_certificate_img)
    ) {
      newErrors.first_aid_certificate_img =
        "Invalid file type. Only PDF, JPG, JPEG, PNG, GIF, and JIF are allowed";
    }

    return newErrors;
  };

  const removeImage = (index: number, name: any) => {
    const updatedImages = [...formData[name]];
    updatedImages.splice(index, 1); // Remove the selected image
    setFormData({ ...formData, [name]: updatedImages });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // window.scrollTo(0, 0);
    const validationErrors: any = validateForm();
    if (Object?.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);
    ProfileSubmitHandlerStep3(
      formData,
      setStep,
      getUser,
      setIsSubmitting,
      UserListRefetch
    );
  };
  console.log(formData.document_id_img, "ahjdhfjs");
  return (
    <>
      <style>{`
        .parent {
          width: auto;
          margin: auto;
          padding: 2rem;
          background: #ffffff;
          border-radius: 25px;
          box-shadow: 7px 20px 20px rgb(210, 227, 244);
        }

        .file-upload {
          text-align: center;
          border: 3px dashed rgb(210, 227, 244);
          padding: 1.5rem;
          position: relative;
          cursor: pointer;
        }

        .file-upload p {
          font-size: 0.87rem;
          margin-top: 10px;
          color: #bbcada;
        }

        .file-upload input {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
        .file-upload span.badge {
          font-size: 0px;
          padding: 2px;
          margin-left: 5px;
        }
          .file-upload img {
            margin: 0 5px;
            border-radius: 5px;
          }
      `}</style>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <h5 className="mb-2">AUSTRALIAN PHOTO ID</h5>
            <FormGroup>
              <Label for="document_id">Document ID</Label>
              <input
                type="text"
                name="document_id"
                value={formData.document_id}
                onChange={handleChange}
                // invalid={!!errors.document_id}
                className={`form-control ${
                  errors.document_id ? "is-invalid" : "is-valid"
                }`}
                // ref={(el: any) => {
                //   fieldRefs.current.document_id = el; // Set ref for the document_id field
                // }}
              />
              <div className="text-danger">{errors?.document_id}</div>
            </FormGroup>
            <FormGroup>
              <div
              // ref={(el: any) => {
              //   fieldRefs.current.document_id_expire = el;
              //   console.log(el, "elllll");
              // }}
              >
                <Label for="document_id_expire">Expiry Date</Label>
                <DatePickerReact
                  name="document_id_expire"
                  // className="form-control"
                  selected={formData?.document_id_expire}
                  onChange={(date: any) =>
                    handleChange(date, "document_id_expire")
                  }
                  dateFormat="dd/MM/yyyy"
                  className={`form-control ${
                    errors.document_id_expire ? "is-invalid" : "is-valid"
                  }`}
                  placeholderText="DD/MM/YYYY"
                  showIcon
                  minDate={new Date()}
                  // ref={(el: any) => {
                  //   fieldRefs.current.document_id_expire = el;
                  //   console.log(el, "elllll");
                  // }}
                />
                <div className="text-danger">{errors?.document_id_expire}</div>
              </div>
            </FormGroup>

            <FormGroup>
              <Label> Upload Documents (Multiple)</Label>
              <div className="file-upload">
                <p id="document_id_img">Click here to upload Documents</p>
                <input
                  type="file"
                  id="document_id_img"
                  name="document_id_img"
                  multiple
                  onChange={(e) =>
                    handleMultipleImagesChange(e, "document_id_img")
                  }
                  className={`form-control ${
                    errors.document_id_img ? "is-invalid" : "is-valid"
                  }`}
                  // ref={(el: any) => {
                  //   fieldRefs.current.document_id_img = el;
                  // }}
                  accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, image/jif"
                />
                {formData?.document_id_img?.length !== 0 ? (
                  formData?.document_id_img?.map((img: any, index: number) => (
                    <>
                      {/* {console.log(img.preview)} */}
                      <Badge
                        color="warning"
                        style={{ position: "absolute" }}
                        onClick={() => removeImage(index, "document_id_img")}
                      >
                        {" "}
                        <Button
                          close
                          aria-label="Cancel"
                          onClick={() => removeImage(index, "document_id_img")}
                        />
                      </Badge>
                      {img?.file?.type?.includes("application/pdf") ||
                      img?.documentType === "pdf" ? (
                        <iframe
                          key={index}
                          src={img.preview || img?.documentPath}
                          // alt={`Document ${index + 1}`}
                          style={{ width: "100px", height: "100px" }}
                        />
                      ) : (
                        <img
                          key={index}
                          src={img.preview || img?.documentPath}
                          alt={`Document ${index + 1}`}
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </>
                  ))
                ) : (
                  <></>
                )}
              </div>

              {errors.document_id_img && (
                <div className="text-danger">{errors.document_id_img}</div>
              )}
            </FormGroup>
          </CardBody>
          <CardBody>
            <h5 className="mb-2">POLICE VERIFICATION</h5>
            <FormGroup>
              <Label for="document_id">Document ID</Label>
              <input
                type="text"
                name="police_verification_id"
                value={formData?.police_verification_id}
                onChange={handleChange}
                // invalid={!!errors?.police_verification_id}
                className={`form-control ${
                  errors.police_verification_id ? "is-invalid" : "is-valid"
                }`}
                // ref={(el: any) => {
                //   fieldRefs.current.police_verification_id = el;
                // }}
              />
              <div className="text-danger">
                {errors?.police_verification_id}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="police_verification_id_expire">Expiry Date</Label>
              <div
              // ref={(el: any) => {
              //   fieldRefs.current.police_verification_id_expire = el;
              // }}
              >
                <DatePickerReact
                  name="police_verification_id_expire"
                  className={`form-control ${
                    errors.police_verification_id_expire
                      ? "is-invalid"
                      : "is-valid"
                  }`}
                  selected={formData?.police_verification_id_expire}
                  onChange={(date: any) =>
                    handleChange(date, "police_verification_id_expire")
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showIcon
                  minDate={new Date()}
                  // ref={(el: any) => {
                  //   fieldRefs.current.police_verification_id_expire = el;
                  // }}
                />
              </div>
              <div className="text-danger">
                {errors?.police_verification_id_expire}
              </div>
            </FormGroup>

            <FormGroup>
              <Label> Upload Documents (Multiple)</Label>
              <div className="file-upload">
                <p id="police_verification_id_img">
                  Click here to upload Documents
                </p>
                <input
                  type="file"
                  id="police_verification_id_img"
                  name="police_verification_id_img"
                  multiple
                  onChange={(e) =>
                    handleMultipleImagesChange(e, "police_verification_id_img")
                  }
                  className={`form-control ${
                    errors.police_verification_id_img
                      ? "is-invalid"
                      : "is-valid"
                  }`}
                  // ref={(el: any) => {
                  //   fieldRefs.current.police_verification_id_img = el;
                  // }}
                  accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, image/jif"
                />
                {formData?.police_verification_id_img?.length !== 0 ? (
                  formData?.police_verification_id_img?.map(
                    (img: any, index: number) => (
                      <>
                        {/* {console.log(img.preview)} */}
                        <Badge
                          color="warning"
                          style={{ position: "absolute" }}
                          onClick={() =>
                            removeImage(index, "police_verification_id_img")
                          }
                        >
                          {" "}
                          <Button
                            close
                            aria-label="Cancel"
                            onClick={() =>
                              removeImage(index, "police_verification_id_img")
                            }
                          />
                        </Badge>
                        {img?.file?.type?.includes("application/pdf") ||
                        img?.documentType === "pdf" ? (
                          <iframe
                            key={index}
                            src={img.preview || img?.documentPath}
                            // alt={`Document ${index + 1}`}
                            style={{ width: "100px", height: "100px" }}
                          />
                        ) : (
                          <img
                            key={index}
                            src={img.preview || img?.documentPath}
                            alt={`Document ${index + 1}`}
                            style={{ width: "100px", height: "100px" }}
                          />
                        )}
                      </>
                    )
                  )
                ) : (
                  <></>
                )}
              </div>

              {errors.police_verification_id_img && (
                <div className="text-danger">
                  {errors.police_verification_id_img}
                </div>
              )}
            </FormGroup>
          </CardBody>
          <CardBody>
            <h5 className="mb-2">WWCC</h5>
            <FormGroup>
              <Label for="wwcc_id">Document ID</Label>
              <input
                type="text"
                name="wwcc_id"
                value={formData.wwcc_id}
                onChange={handleChange}
                // invalid={!!errors.wwcc_id}
                className={`form-control ${
                  errors.wwcc_id ? "is-invalid" : "is-valid"
                }`}
                // ref={(el: any) => {
                //   fieldRefs.current.wwcc_id = el;
                // }}
              />
              <div className="text-danger">{errors?.wwcc_id}</div>
            </FormGroup>
            <FormGroup>
              <Label for="wwcc_id_expire">Expiry Date</Label>
              <DatePickerReact
                name="wwcc_id_expire"
                selected={formData?.wwcc_id_expire}
                onChange={(date: any) => handleChange(date, "wwcc_id_expire")}
                dateFormat="dd/MM/yyyy"
                className={`form-control ${
                  errors.wwcc_id_expire ? "is-invalid" : "is-valid"
                }`}
                placeholderText="DD/MM/YYYY"
                showIcon
                minDate={new Date()}
                // ref={(el: any) => {
                //   fieldRefs.current.wwcc_id_expire = el;
                // }}
              />
              <div className="text-danger">{errors?.wwcc_id_expire}</div>
            </FormGroup>

            <FormGroup>
              <Label> Upload Documents (Multiple)</Label>
              <div className="file-upload">
                <p id="wwcc_id_img">Click here to upload Documents</p>
                <input
                  type="file"
                  id="wwcc_id_img"
                  name="wwcc_id_img"
                  multiple
                  onChange={(e) => handleMultipleImagesChange(e, "wwcc_id_img")}
                  className={`form-control ${
                    errors.wwcc_id_img ? "is-invalid" : "is-valid"
                  }`}
                  accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, image/jif"
                  // ref={(el: any) => {
                  //   fieldRefs.current.wwcc_id_img = el;
                  // }}
                />
                {formData?.wwcc_id_img?.length !== 0 ? (
                  formData?.wwcc_id_img?.map((img: any, index: number) => (
                    <>
                      {/* {console.log(img.preview)} */}
                      <Badge
                        color="warning"
                        style={{ position: "absolute" }}
                        onClick={() => removeImage(index, "wwcc_id_img")}
                      >
                        {" "}
                        <Button
                          close
                          aria-label="Cancel"
                          onClick={() => removeImage(index, "wwcc_id_img")}
                        />
                      </Badge>
                      {img?.file?.type?.includes("application/pdf") ||
                      img?.documentType === "pdf" ? (
                        <iframe
                          key={index}
                          src={img.preview || img?.documentPath}
                          // alt={`Document ${index + 1}`}
                          style={{ width: "100px", height: "100px" }}
                        />
                      ) : (
                        <img
                          key={index}
                          src={img.preview || img?.documentPath}
                          alt={`Document ${index + 1}`}
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </>
                  ))
                ) : (
                  <></>
                )}
              </div>

              {errors.wwcc_id_img && (
                <div className="text-danger">{errors.wwcc_id_img}</div>
              )}
            </FormGroup>
          </CardBody>
          <CardBody>
            <h5 className="mb-2">FIRST AID CERTIFICATE</h5>
            <FormGroup>
              <Label for="first_aid_certificate_id">Document ID</Label>
              <input
                type="text"
                name="first_aid_certificate_id"
                value={formData.first_aid_certificate_id}
                onChange={handleChange}
                // invalid={!!errors.first_aid_certificate_id}
                className={`form-control ${
                  errors.first_aid_certificate_id ? "is-invalid" : "is-valid"
                }`}
                // ref={(el: any) => {
                //   fieldRefs.current.first_aid_certificate = el;
                // }}
              />
              <div className="text-danger">
                {errors?.first_aid_certificate_id}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="first_aid_certificate_expire">Expiry Date</Label>
              <div
              // ref={(el: any) => {
              //   fieldRefs.current.first_aid_certificate_expire = el;
              // }}
              >
                {" "}
                <DatePickerReact
                  name="first_aid_certificate_expire"
                  selected={formData?.first_aid_certificate_expire}
                  onChange={(date: any) =>
                    handleChange(date, "first_aid_certificate_expire")
                  }
                  dateFormat="dd/MM/yyyy"
                  className={`form-control ${
                    errors.first_aid_certificate_expire
                      ? "is-invalid"
                      : "is-valid"
                  }`}
                  placeholderText="DD/MM/YYYY"
                  showIcon
                  minDate={new Date()}
                  // ref={(el: any) => {
                  //   fieldRefs.current.first_aid_certificate_expire = el;
                  // }}
                />
              </div>
              <div className="text-danger">
                {errors?.first_aid_certificate_expire}
              </div>
            </FormGroup>

            <FormGroup>
              <Label> Upload Documents (Multiple)</Label>
              <div className="file-upload">
                <p id="first_aid_certificate_img">
                  Click here to upload Documents
                </p>
                <input
                  type="file"
                  id="first_aid_certificate_img"
                  name="first_aid_certificate_img"
                  multiple
                  onChange={(e) =>
                    handleMultipleImagesChange(e, "first_aid_certificate_img")
                  }
                  className={`form-control ${
                    errors.first_aid_certificate_img ? "is-invalid" : "is-valid"
                  }`}
                  accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, image/jif"
                  // ref={(el: any) => {
                  //   fieldRefs.current.first_aid_certificate_img = el;
                  // }}
                />
                {formData?.first_aid_certificate_img?.length !== 0 ? (
                  formData?.first_aid_certificate_img?.map(
                    (img: any, index: number) => (
                      <>
                        {/* {console.log(img.preview)} */}
                        <Badge
                          color="warning"
                          style={{ position: "absolute" }}
                          onClick={() =>
                            removeImage(index, "first_aid_certificate_img")
                          }
                        >
                          {" "}
                          <Button
                            close
                            aria-label="Cancel"
                            onClick={() =>
                              removeImage(index, "first_aid_certificate_img")
                            }
                          />
                        </Badge>
                        {img?.file?.type?.includes("application/pdf") ||
                        img?.documentType === "pdf" ? (
                          <iframe
                            key={index}
                            src={img.preview || img?.documentPath}
                            // alt={`Document ${index + 1}`}
                            style={{ width: "100px", height: "100px" }}
                          />
                        ) : (
                          <img
                            key={index}
                            src={img.preview || img?.documentPath}
                            alt={`Document ${index + 1}`}
                            style={{ width: "100px", height: "100px" }}
                          />
                        )}
                      </>
                    )
                  )
                ) : (
                  <></>
                )}
              </div>

              {errors.first_aid_certificate_img && (
                <div className="text-danger">
                  {errors.first_aid_certificate_img}
                </div>
              )}
            </FormGroup>
            {Object.keys(errors).length === 1 &&
            Object.keys(errors).length !== 0 ? (
              <>
                {" "}
                <div>
                  <Alert color="danger" className="mt-30" id="g">
                    Please make sure all fields are filled correctly.
                  </Alert>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="text-center mt-5 d-float-center justify-content-center gap-15">
              {" "}
              <Button
                className="btn btn-brand-2"
                type="submit"
                onClick={() => [setStep(2)]}
              >
                <i className="ri-arrow-left-line"></i>&nbsp;BACK
              </Button>
              <Button
                className="btn btn-brand-1"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size="sm"></Spinner>}
                Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
              </Button>
            </div>
          </CardBody>
        </Card>
      </form>
    </>
  );
};

export default Step3;
