import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import constants from "../../../Utils/Constants/Constants";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  calculateAge,
  calculateTotalHours_,
  CapitalFirstLetter,
  capitalizeFirstLetterOfEachWord,
  formatAmount,
  getURLPassData,
  stripePaymentStatus,
} from "../../../Utils/helpers/helpers";
import { useQuery } from "react-query";
import { getNDISData, getNDISDataOTP } from "../../../Api/WebsiteApi";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { BaseUrl } from "../../../Api/BaseUrl";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import NDISOTP from "./NDISOTP";
import {
  careSeekeAge,
  careSeekeGenderFull,
} from "../../../Utils/helpers/careseekerGetData";
export default function NDISApproval() {
  const [otp, setOTP] = useState(1);
  const id: any = getURLPassData();
  // Initial values for the form
  const initialValues = {
    description: "",
    agreeToTerms: false,
  };
  const validationSchema = Yup.object().shape({
    agreeToTerms: Yup.bool().oneOf([true], "You must agree to the terms"),
    description: Yup.string().when([], (fields, schema) => {
      if (actionType === "reject") {
        return schema.required("Note is required ");
      }
      return schema.notRequired();
    }),
  });

  const navigate = useNavigate();
  // const [actionType, setActionType] = useState<any>("");
  // // Handle form submission
  let actionType = ""; // Track button click
  const handleButtonClick = (type: string) => {
    actionType = type; // Track which button is clicked
  };
  const handleSubmit = (values: any, status: any) => {
    console.log(values, "valuesvaluesvalues");

    Swal.fire({
      title:
        status === "accept"
          ? "Are you sure you want to accept this request?"
          : " Are you sure you want to reject this request?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Don't save`,
      icon: "warning",
    }).then((result) => {
      if (result?.isConfirmed) {
        console.log(values); // Handle form submission here
        const formData: any = new FormData();
        formData.append("id", id);
        formData.append("is_approved", status === "accept" ? true : false);
        formData.append("rejected_reason", values?.description);

        axios({
          method: "post",
          url: `${BaseUrl}/user/booking/ndis/request/approval`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          },
        })
          .then(function (response: any) {
            // console.log(response, "response");
            if (response?.data?.status === true) {
              Swal.fire({
                title: "Success!",
                text: "Changes done successfully",
                icon: "success",
              });
              // actions?.setSubmitting(true);
              navigate("/NDIS-approval-success");
              localStorage.removeItem("OTP_KEY");
              // toast.success("Detiles added successfully");
            }
          })
          .catch(function (response) {
            if (response?.response?.data?.errors) {
              // setValidationErrors(response?.response?.data?.errors, actions);
            } else {
              Swal.fire({
                title: "Error!",
                text: response?.response?.data?.message,
                icon: "error",
              });
              // actions?.setSubmitting(false);
            }
          });
      }
    });
  };

  const [code, setCode] = useState<any>();
  const fetchOTP = (id: any) => {
    if (!localStorage.getItem("OTP_KEY")) {
      const api = axios.create({
        baseURL: `${BaseUrl}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
        },
      });

      // Example API call
      api
        .get(`/user/booking/ndis/request/otp/${id}`)
        .then((response) => {
          console.log(response?.data, "code");
          setCode(response?.data?.data?.verificationCode);
          localStorage.setItem(
            "OTP_KEY",
            response?.data?.data?.verificationCode
          );
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  useEffect(() => {
    // Function to make the API call

    // Initial call
    fetchOTP(id);

    // Set up a timer to call the function every 1 minute (60000 ms)
    const interval = setInterval(fetchOTP, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [id]); //
  const [dataList, setdataList] = useState<any>([]);
  // const { data: dataList }: any = useQuery(
  //   [`getNDISDataOTP`, localStorage.getItem("OTP_KEY")],
  //   () =>
  //     localStorage.getItem("OTP_KEY") !== undefined ? getNDISData(id) : "",
  //   {
  //     keepPreviousData: true,
  //     staleTime: 1000,
  //   }
  // );

  const formatTimeWithAMPM = (time24: any) => {
    if (time24) {
      const [hours, minutes] = time24?.split(":")?.map(Number);
      const suffix = hours >= 12 ? "PM" : "AM";
      const hours12 = hours % 12 || 12; // Convert 0 or 12-hour to 12 in AM/PM format
      return `${hours12}:${minutes.toString().padStart(2, "0")} ${suffix}`;
    }
  };
  const formattedTasks = dataList?.booking_details?.additionalTasks?.map(
    (task: any) => ({
      value: task,
      label: task,
    })
  );
  console.log(localStorage.getItem("OTP_KEY"), "sjdhjsah");

  return (
    <>
      <Container className="ndis-container my-4">
        <Card className="ndis-page">
          <CardHeader className="p-25">
            <table className="w-100 ndis-table ndis-head">
              <thead>
                <tr>
                  <th>
                    <h3 className="m-0 ndis-title-head">
                      {otp === 2 && (
                        <>
                          {" "}
                          {
                            dataList?.ndisPaymentRequestId
                          } -{" "}
                        </>
                      )}{" "}
                      NDIS approval request{" "}
                      {otp === 2 && (
                        <>
                          for{" "}
                          {
                            dataList?.booking_details?.careseeker_details
                              ?.firstName
                          }{" "}
                          &nbsp;{" "}
                          {
                            dataList?.booking_details?.careseeker_details
                              ?.lastName
                          }
                        </>
                      )}
                    </h3>
                  </th>
                  <th>
                    <div className="header-logo">
                      <a className="logo-link" href="#">
                        <img
                          className="uss-logo01"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
                          alt="USS Logo"
                        />
                      </a>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </CardHeader>
          {otp === 1 ? (
            <>
              <tr>
                <td colSpan={2} className="pe-0" style={{ width: "65%" }}>
                  <NDISOTP
                    otp={otp}
                    setOTP={setOTP}
                    dataList={code}
                    fetchOTP={fetchOTP}
                    id={id}
                    setdataList={setdataList}
                  />
                </td>
              </tr>
            </>
          ) : (
            <>
              <CardBody className="p-25">
                <table className="w-100 ndis-table ndis-body">
                  <tbody>
                    <tr>
                      <td className="pe-4" style={{ width: "65%" }}>
                        <div className="ndis-booking-info mt-1 mb-2">
                          <p
                            className="ps-0"
                            style={{ fontSize: 20, marginBottom: 6 }}
                          >
                            <i className="fa fa-user" aria-hidden="true" />
                            &nbsp;
                            <span style={{ fontWeight: 700 }}>
                              {
                                dataList?.booking_details?.careseeker_details
                                  ?.firstName
                              }{" "}
                              &nbsp;{" "}
                              {
                                dataList?.booking_details?.careseeker_details
                                  ?.lastName
                              }
                            </span>
                          </p>
                          {/* <p>
                            NDIS ID:&nbsp;<span>ND23451</span>
                          </p> */}
                          <p>
                            <i className="ri-information-line"></i>&nbsp;
                            {careSeekeGenderFull(
                              dataList?.booking_details
                            )}, {careSeekeAge(dataList?.booking_details)}
                          </p>
                          <p>
                            <i className="ri-map-pin-line"></i>
                            &nbsp;Address:&nbsp;
                            {CapitalFirstLetter(
                              dataList?.booking_details?.careseeker_details
                                ?.careseeker_details?.suburb?.locality
                            )}
                            &nbsp;-&nbsp;
                            {
                              dataList?.booking_details?.careseeker_details
                                ?.careseeker_details?.suburb?.state
                            }{" "}
                            (
                            {
                              dataList?.booking_details?.careseeker_details
                                ?.careseeker_details?.suburb?.postcode
                            }
                            )
                          </p>
                        </div>
                      </td>
                      <td style={{ width: "35%" }}>
                        <div className="ndis-booking-status mt-1 mb-2 text-end">
                          <p>
                            <span className="input">Estimated Cost</span>
                          </p>
                          <h5 className="service-type mb-2">
                            <span className="input">
                              {otp === 1 ? (
                                <Skeleton count={1} />
                              ) : (
                                <>
                                  {" "}
                                  $
                                  {formatAmount(
                                    dataList?.booking_details?.totalAmount
                                  )}
                                </>
                              )}
                            </span>
                          </h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <hr className="hr-main-ndis uss-secondary" />
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2} className="pe-4" style={{ width: "65%" }}>
                        <div className="ndis-booking-info mt-2">
                          <h5 className="mb-3 box-head01">Service Details</h5>
                          {dataList?.booking_details?.careseeker_required_services?.map(
                            (item: any) => {
                              return (
                                <>
                                  {" "}
                                  <h5 className="text-secondary mb-1 ps-2">
                                    <span className="input">
                                      Requested Service:
                                    </span>
                                    &nbsp;
                                    <span className="output">{item?.name}</span>
                                  </h5>
                                  <p className="ps-2">
                                    <i className="ri-calendar-2-line"></i>{" "}
                                    Scheduled on:&nbsp;
                                    <span>
                                      {moment(
                                        dataList?.booking_details
                                          ?.careseeker_required_service_days?.[0]
                                          ?.startDate
                                      ).format("ddd")}
                                      ,
                                      {moment(
                                        dataList?.booking_details
                                          ?.careseeker_required_service_days?.[0]
                                          ?.startDate
                                      ).format("DD MMM YYYY")}
                                      ,{" "}
                                      {formatTimeWithAMPM(
                                        dataList?.booking_details
                                          ?.careseeker_required_service_days?.[0]
                                          ?.startTime
                                      )}{" "}
                                      to{" "}
                                      {formatTimeWithAMPM(
                                        dataList?.booking_details
                                          ?.careseeker_required_service_days?.[0]
                                          ?.endTime
                                      )}
                                    </span>
                                  </p>
                                  <p className="ps-2">
                                    <i className="ri-time-line"></i>{" "}
                                    Duration:&nbsp;
                                    <span>
                                      {calculateTotalHours_(
                                        dataList?.booking_details
                                          ?.careseeker_required_service_days?.[0]
                                          ?.startTime,
                                        dataList?.booking_details
                                          ?.careseeker_required_service_days?.[0]
                                          ?.endTime
                                      )}{" "}
                                      Hours
                                    </span>
                                  </p>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                        <div className="ndis-booking-info mt-2">
                          <h5 className="text-secondary mb-1 ps-2">
                            <span className="input">Service Provider:</span>
                            &nbsp;
                            <span className="output">USStaffing Ltd.</span>
                          </h5>
                          <p className="ps-2">
                            <i className="ri-map-pin-line"></i>
                            &nbsp;Address:&nbsp;
                            <span>19 Woronora Ave, Leumeah NSW 2560, AUS</span>
                          </p>
                          <p className="ps-2">
                            <i className="ri-phone-line"></i> Contact No.:&nbsp;
                            <span>1300 052 00</span>
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className="ndis-booking-info mt-4">
                          <h5 className="mb-2 box-head01">
                            Booking Instructions
                          </h5>
                          <p className="ps-2">
                            {dataList?.booking_details?.instruction
                              ? dataList?.booking_details?.instruction
                              : "---No data---"}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className="ndis-booking-info mt-4">
                          <h5 className="mb-2 box-head01">Task List</h5>
                          <p className="ps-2 task-list">
                            <ul className="task-list-ul">
                              {formattedTasks?.length === 0 ? (
                                "---No data---"
                              ) : (
                                <>
                                  {formattedTasks?.map((item: any) => {
                                    return (
                                      <>
                                        <li>{item?.label}</li>
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </ul>
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <hr className="hr-sub-main-ndis uss-secondary" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="pt-4">
                        <table>
                          <tbody>
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema} // Apply the validation schema
                              onSubmit={(values, { setSubmitting }) => {
                                if (actionType === "reject") {
                                  handleSubmit(values, "reject");
                                } else if (actionType === "accept") {
                                  handleSubmit(values, "accept");
                                }
                                setSubmitting(false); // Stop submission spinner
                              }}
                            >
                              {({
                                setFieldValue,
                                errors,
                                touched,
                                values,
                                handleSubmit,
                                setSubmitting,
                              }) => (
                                <>
                                  {console.log(errors, values, "ashgashga")}
                                  <div className="mt-5">
                                    <Form
                                      className="row"
                                      onSubmit={handleSubmit}
                                    >
                                      <Col lg="6" className="pe-4">
                                        <div className="form-check">
                                          <Field
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                            name="agreeToTerms"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="exampleCheck1"
                                          >
                                            I, as an authorized representative
                                            of{" "}
                                            {/* {capitalizeFirstLetterOfEachWord(
                                              dataList?.booking_details
                                                ?.careseeker_details
                                                ?.careseeker_details
                                                ?.ndisContactName
                                            )} */}{" "}
                                            NDIS Provider , hereby declare that
                                            I am authorized to sign this
                                            insurance approval request of the
                                            NDIS participant,{" "}
                                            <span style={{ fontWeight: 600 }}>
                                              {capitalizeFirstLetterOfEachWord(
                                                dataList?.booking_details
                                                  ?.careseeker_details
                                                  ?.firstName
                                              )}
                                              &nbsp;
                                              {capitalizeFirstLetterOfEachWord(
                                                dataList?.booking_details
                                                  ?.careseeker_details?.lastName
                                              )}
                                            </span>
                                          </label>
                                          {errors.agreeToTerms &&
                                            touched.agreeToTerms && (
                                              <div className="text-danger">
                                                {errors.agreeToTerms}
                                              </div>
                                            )}
                                        </div>

                                        <div className="ndis-booking-info mt-3 mb-2">
                                          <p className="mb-2">NDIS Manager,</p>
                                          <p
                                            className="ps-0"
                                            style={{
                                              fontSize: 20,
                                              marginBottom: 6,
                                            }}
                                          >
                                            <span style={{ fontWeight: 700 }}>
                                              {capitalizeFirstLetterOfEachWord(
                                                dataList?.booking_details
                                                  ?.careseeker_details
                                                  ?.careseeker_details
                                                  ?.ndisContactName
                                              )}
                                            </span>
                                          </p>
                                          <p className="ps-0">
                                            <i className="ri-map-pin-line"></i>
                                            &nbsp;Address:&nbsp;
                                            <span>
                                              19 Woronora Ave, Leumeah NSW 2560,
                                              AUS
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="6">
                                        {/* Description textarea */}
                                        <FormGroup className="form-group pb-3">
                                          <label>Note</label>
                                          <Field
                                            as="textarea"
                                            name="description"
                                            className={`form-control textarea ${
                                              errors.description &&
                                              touched.description
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            placeholder="Type..."
                                          />
                                          {/* Display error message for description */}
                                          {errors.description &&
                                            touched.description && (
                                              <div className="text-danger">
                                                {errors.description}
                                              </div>
                                            )}
                                        </FormGroup>

                                        {/* <hr className="hr-main-ndis uss-secondary" /> */}

                                        <div className="d-float-center gap-15 justify-content-end my-0 mt-4">
                                          <button
                                            type="submit"
                                            className="btn btn-default2"
                                            onClick={() => {
                                              setSubmitting(true);
                                              handleButtonClick("reject");
                                            }}
                                          >
                                            Reject
                                          </button>

                                          {/* Approve Button */}
                                          <button
                                            type="submit"
                                            className="btn btn-default"
                                            onClick={() => {
                                              setSubmitting(true);

                                              handleButtonClick("accept");
                                            }}
                                          >
                                            Approve Request
                                          </button>
                                        </div>
                                      </Col>
                                    </Form>
                                  </div>
                                </>
                              )}
                            </Formik>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
