export const initialValuesStep1 = {
  first_name: "",
  email: "",
  mobile: "",
  suppot_for_you: "",
  suburb: null,
  dob: "",
  gender: "",
  address: "",
  last_name: "",
  street_number:"",
  address_line_one:"",
  address_line_two:"",
  state:"",
  profile_img:"",
  profile_img_:""
};
export const initialValuesStep2 = {
  payment_mode: "",
  ndis: "",
  plan_manager_name: "",
  contact_name_ndis: "",
  contact_number_dis: null,
  email: "",
  ndis_contact_email:""
};
export const initialValuesStep3 = {
  summary: "",
  care_request_title: "",
  health_status: "",
};
