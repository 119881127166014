import Swal from "sweetalert2";
import { api } from "../../../Api/Api";

export const OTPSubmitHandler = (
  values: any,
  actions: any,
  setOTP: any,
  dataList: any,
  id: any,
  setdataList: any
) => {
  actions.setSubmitting(true);
  api
    .post(
      "/user/booking/ndis/request/details",
      {
        otp: values?.otp?.join(""),
        verification_code: dataList
          ? dataList
          : localStorage.getItem("OTP_KEY"),
        id: id,
      },
      false
    )
    .then(async function ([success, response]: any) {
      if (response?.status === true) {
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
        });
        setOTP(2);
        setdataList(response?.data || []);
        actions.setSubmitting(false);
      }
      if (response?.status === false) {
        Swal.fire({
          title: "Error!",
          text: response?.message,
          icon: "error",
        });
        actions.setSubmitting(false);
      }
    })
    .catch((err) => {
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        localStorage.removeItem("OTP_KEY");
        Swal.fire({
          title: "Error!",
          text: err?.response?.data?.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: err?.response?.data?.message,
          icon: "error",
        });
      }
    });
};
